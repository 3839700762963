@import url("https://fonts.googleapis.com/css2?family=Raleway:wght@500&display=swap");

body {
  margin: 0;
  font-family: "Raleway", serif !important;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  /* background-image: linear-gradient(to left, rgb(27 20 41), rgb(20 15 35)); */
}

body {
  margin: 0px;
  box-sizing: border-box;
  padding: 0px;
  position: relative;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

@media (min-width: 480px) {
  body {
    background-color: lightgreen;
  }
}


:root {
  font-family: Inter, system-ui, Avenir, Helvetica, Arial, sans-serif;
  line-height: 1.5;
  font-weight: 400;

  color-scheme: light dark;
  color: black;
  background-color: white;
  overflow-x: hidden;

  font-synthesis: none;
  text-rendering: optimizeLegibility;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

a {
  font-weight: 500;
  color: #646cff;
  text-decoration: inherit;
}

a:hover {
  color: #535bf2;
}

body {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
}

h1 {
  font-size: 3.2em;
  line-height: 1.1;
}

button {
  border-radius: 8px;
  border: 1px solid transparent;
  padding: 0.6em 1.2em;
  font-size: 1em;
  font-weight: 500;
  font-family: inherit;
  background-color: #1a1a1a;
  cursor: pointer;
  transition: border-color 0.25s;
}

button:hover {
  border-color: #646cff;
}

button:focus,
button:focus-visible {
  outline: 4px auto -webkit-focus-ring-color;
}

@media (prefers-color-scheme: light) {
  :root {
    color: #213547;
    background-color: #ffffff;
  }

  a:hover {
    color: #747bff;
  }

  button {
    background-color: #f9f9f9;
  }
}

.CLoor {
  color: green;
}

/* .MainBox{
    position: fixed;
    z-index: 9999;
    inset: 16px;
    pointer-events: none;
} */

.pos-r {
  position: relative;
}

.epwrBd {
  top: 0px;
  left: 0px;
  right: 0px;
}

.pos-a {
  position: absolute;
}

.iucATT {
  min-height: 390px;
  background: rgb(254, 159, 75);
  position: relative;
  overflow: hidden;
  z-index: -101;
  clip-path: polygon(0px 0px, 100% 0px, 100% 40%, 0px 100%);
}

.iucATT>div {
  position: absolute;
  filter: blur(60px);
  opacity: 0.8;
}

.full-width {
  width: 100%;
}


.iucATT .one {
  border-radius: 70%;
  width: 500px;
  height: 500px;
  background-color: rgb(181, 113, 246);
  left: -50px;
  top: -300px;
  z-index: 3;
  animation: 9s linear 0s infinite normal none running fly;
  transform: rotate(0deg) translate(80px) rotate(0deg);
}

.iucATT .two {
  width: 400px;
  height: 800px;
  background-color: rgb(251, 91, 99);
  bottom: -30px;
  left: -80px;
}

.iucATT .three {
  border-radius: 100%;
  width: 450px;
  height: 750px;
  bottom: -80px;
  right: -100px;
  background-color: rgb(144, 224, 255);
  animation: 5s linear 0s infinite normal none running;
  transform: rotate(0deg) translate(100px) rotate(0deg);
}

.iucATT .four {
  border-radius: 100%;
  width: 450px;
  height: 750px;
  inset: 0px 50% -80px;
  background-color: rgb(255, 203, 87);
  animation: 5s linear 0s infinite normal none running;
  transform: rotate(0deg) translate(100px) rotate(0deg);
}



.mainBox {
  width: 100%;
  height: 100%;
}

*,
*::before,
*::after {
  box-sizing: inherit;
}


.LAble {
  color: #4793AF;
  font-weight: bold;
  padding-bottom: 1rem;
  padding-top: 22px;
}

.FontSize {
  font-size: 2rem;

  margin-left: 1rem;
}

.Tiangle {

  width: 0;
  height: 0;
  border-top: 25px solid transparent;
  border-right: 50px solid #555;
  border-bottom: 25px solid transparent;

}

.Bnner {
  background-color: #EFEFEF;
  height: 642px;
  width: 100%;
}


.Ftame1 {
  height: 14.5rem;
  width: 10rem;
  object-fit: contain;
  position: relative;
  left: 12rem;
  box-shadow: 10px 10px 17px -2px rgba(0, 0, 0, 0.32);

}

.Frame2 {
  height: 14.5rem;
  width: 10rem;
  object-fit: contain;
  position: relative;
  top: -184px;
  left: 4rem;
  box-shadow: 10px 10px 17px -2px rgba(0, 0, 0, 0.32);
}

.Fmare3 {
  height: 14.5rem;
  width: 10rem;
  object-fit: contain;
  position: relative;
  bottom: 13rem;
  box-shadow: 10px 10px 17px -2px rgba(0, 0, 0, 0.32);
  left: 13rem;
}





.Bnner img {
  max-width: 100%;
  height: auto;
  /* This maintains the image's aspect ratio */
}

.Eleveret {
  position: relative;
  top: -403px;
  margin-left: 2rem;
  font-weight: bold;
  font-size: 1.7rem;
}

.Text {
  text-align: center;
}

.P30 {
  padding: 0rem 4rem 0rem 4rem;
}

.Font2 {
  line-height: 1.7;

  font-size: 2rem;
}

.FotnWe {
  font-weight: 400;
}

.P40 {

  font-size: 1.8rem;
  color: #4793AF !important;
  line-height: 1.4;
  margin-top: 20px;
  padding: 2rem 1.1rem 0rem 1.1rem;
  ;
}

.crorseul {
  overflow-y: scroll;
}


.Crolse {
  display: flex;
  overflow-y: scroll;
  padding-right: 26px;

  padding-bottom: 32px;

}

.Crolse img {
  margin-left: 20px;
  height: 498px;
  width: auto;

}


.Button {
  margin-top: 20px;
  padding-bottom: 20px;
  font-size: 1.3rem;

}

.Buttonstyle {
  background-color: #A44039;
  color: white;
  border-radius: 60px;
}



.Colomn {
  display: flex;
  flex-direction: column;

}

.Pacleholer {
  color: #1a1e3e;

}

.Iamgeshoee {

  width: 280px;
  height: 400px;
  border-radius: 1rem;
  margin: auto auto 1rem auto;
  border: 1px solid #e8eaef;
  background-color: #e8eaef;

}

.iamgebox {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  height: 100%;
  width: 100%;

}

.add-img-icon {
  height: 70px;
  width: 70px;
}



.cincross {
  height: 32px;
  width: 20px;
  right: -45px;
  top: 2px;
  color: red;
  border-radius: 15px;
  background: #e2e2e2;
  padding: 3px;
  display: block;
  position: relative;
  left: 285px;

}

.inputBox {
  width: 100%;
  margin-top: 10px;
  height: 50px;
  display: flex;
  border: 1px solid #bdbdbd;
  text-decoration: none;
  width: 100%;
  margin-top: 10px;
  height: 50px;
  border-radius: 4px;
  color: black;
  padding-left: 10px;
  justify-content: space-between;
}
.inputBox :focus{
  border-color: 1px solid gray;
}

.maininputBox {
  display: flex;
  justify-content: center;
  align-items: baseline;

}

.inpiutdipaybox {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
}



.Boxinput {
  width: 100%;
  padding: 10px;
  display: flex;
  flex-direction: column;
}
.Buttonocn{
  background-color: white;
  color: black;
  height: 30px;
  width: 30px;
}

.iamgeboxsec {
  width: 100%;
  height: 100%;
  object-fit: contain;
  display: flex;
  position: relative;
}

.PreviewTemplate {
  justify-content: center;
  display: flex;
  height: 340px;
  overflow: scroll;
}

.lukh {
  width: 500px;
  height: 90%;
  padding-left: 10px;
  padding-right: 10px;

}